import { graphql, useStaticQuery,Link } from "gatsby"
import React,{useLayoutEffect} from "react";
import Nav from "../layouts/NavBar";
import SEO from '../components/seo';
import Footer from '../components/Footer';
import NewsLetter from '../components/NewsLetter';
import TypeWriter from "typewriter-effect";
import moment from 'moment-with-locales-es6';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
	faArrowRight
} from '@fortawesome/free-solid-svg-icons';


import Lottie from 'react-lottie';
import * as anoJson from '../anin/ano-novo.json'

const IndexPage = () => {

  const {directus:{Artigos} } = useStaticQuery(graphql`
      query allPosts {
  directus {
    Artigos(sort: "-date_created") {
      id
      date_created
      editor
	  slug
      keywords
      referencias
      resumo
      tags
      title
      imagem_principal {
        id
        description
        title
		filename_download
      }
    }
  }
}
  `);

	//console.log(Artigos);
	//

	const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: anoJson,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <SEO 
            title='Gestão de Conhecimento e dicas para potencializar os resultados de sua empresa.'
            description='A revista da Shelfin foca em trazer os melhores conteúdos sobre Gestão de Conhecimento, Gestão e pessoas e People Analytics e muitos mais conteúdos para te ajudar a potencializar os resultados de sua empresa.'
      />
      <div className="flex flex-col text-gray-800  w-full lg:w-3/4">
          <Nav/>
			<div className="flex flex-col space-y-2 p-4 lg:p-0">
				<a href="https://shelfin.com.br/contato" className="p-2 px-6 text-blue-600 font-medium flex flex-row justify-between items-center text-xl bg-blue-200 rounded-lg">
					<div className="flex flex-row items-center space-x-2">
						<div className="p-4">
							🎉
						</div>
						<div className="">Comece seu 2023 com o melhor de seus colaboradores! Agende uma conversa conosco</div>
					</div>
					<div> <FontAwesomeIcon icon={faArrowRight}/> </div>

				</a>
				<div className="flex w-full items-center h-72">
					<div className="flex flex-col space-y-2 w-full lg:w-2/3">
						<div className="text-gray-800 text-5xl font-medium">
							Feito por gente para GENTE.	
						</div>
						<div className="font-light text-2xl">
							Mantenha-se informado, encontre temas relevantes e auxilie seus colaboradores em seu desenvolvimento contínuo 
						</div>
					</div>
				</div>
			</div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8 px-4 lg:px-0">
            {Artigos.map((artigo,index) => {
              moment.locale('pt-br');
              var data = moment(artigo.date_created).format('LL');

              return(
				<div className={(index ==0 ?'col-span-1 lg:col-span-3 row-span-2':'') +" flex flex-col space-y-2"}>
					<Link to={`/${artigo.slug}`}>
				  {
					index == 0 ? 
						<div className={"relative grid grid-cols-1 lg:grid-cols-2 items-center gap-8 space-y-2 w-full"}> 
							<div class="">
								<img src={`${process.env.GATSBY_BASE_URL}assets/${artigo.imagem_principal.id}/${artigo.imagem_principal.filename_download}`} alt={artigo.title} className="aspect-w-16 aspect-h-9 w-full object-cover bg-blue-50 rounded-md"/>
							</div>	
							<div class="flex flex-col space-y-2">
								<div className="text-gray-800 font-bold text-3xl line-clamp-2">
									{artigo.title}
								</div>
								<div className="w-full flex flex-wrap">
									{artigo.tags.map(tag =>{ 
										return(<div class="px-2 text-white rounded-md" style={{backgroundColor:'#ff0c9b'}}>{tag}</div>);
									})}
								</div>
								<div className="w-full text-justify line-clamp-5" dangerouslySetInnerHTML={{ __html:artigo.resumo}}>
								</div>
								<div>
									<div className="text-base font-light">
										{`${data}`}
									</div>
								</div>
							</div>
							
						</div>
					  : 
					  <div className={"relative flex flex-col space-y-2 w-full"}> 
						<img src={`${process.env.GATSBY_BASE_URL}assets/${artigo.imagem_principal.id}/${artigo.imagem_principal.filename_download}`} alt={artigo.title} className="aspect-w-16 h-60 w-full object-cover bg-blue-50 rounded-md"/>
                        <div className="text-gray-800 font-bold text-lg line-clamp-2">
                          {artigo.title}
                        </div>
						<div className="w-full flex flex-wrap">
							{artigo.tags.map(tag =>{ 
								return(<div class="px-2 text-white rounded-md" style={{backgroundColor:'#ff0c9b'}}>{tag}</div>);
							})}
						</div>
                        <div>
                            <div className="text-base font-light">
                              {`${data}`}
                            </div>
                        </div>
                    </div> 				  } 
                </Link>
				  {index == 0 ? 
					<div className="flex text-center items-center justify-center h-32 text-2xl text-gray-700">
						<div>Veja <span style={{color:'#FF0C9B'}} className="font-bold">+</span> artigos que podem ser do seu interesse!</div>
					</div>
					: <div></div>
				  }
				</div> 
              );
            })}
          </div>
      </div>
      <Footer/>
    </div>
  )
}
export default IndexPage
